import React from 'react'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import { StaticImage } from 'gatsby-plugin-image'
import { gql, useMutation } from '@apollo/client'
import { v4 as uuidv4 } from 'uuid'
import { useStaticQuery, graphql } from 'gatsby'
import { SocialMediaLinks } from '../../utils'
import './page-contact-us.css'


const SEND_INQUIRY_MUTATION = gql`
mutation SendInquiryMutation ($id: String!, $name: String!, $email: String!, $message: String!) {
    createSubmission(input: { clientMutationId: $id, name: $name, email: $email, message: $message}) {
        data
        success
    }
}
`

const SendingStatus = ({ message }) => {
    return (
        <Layout>
            <div className="grid text-4xl place-content-center place-items-center md:text-7xl" style={{height:'50vh'}}>
                <h3 data-text={message} className="inquiry-loading">{message}</h3>
            </div>
        </Layout>
    )
}

const PageContactUs = ({ pageContext, location }) => {
    const page = pageContext.page;

    const data = useStaticQuery(graphql`
    query {
        favicon: file(relativePath: {eq: "favicon-w200.png"}) {
            id
            childImageSharp {
                original {
                    src
                }
            }
        }
    }`)

    const [sendInquiry, { /*data,*/ loading, error }] = useMutation(SEND_INQUIRY_MUTATION);

    let name;
    let email;
    let message;
    let status;

    if (loading) return <SendingStatus message={`Loading...`}/>

    if (error) return <SendingStatus message={`Submission Error: ${error.message}`} />

    return (
        <Layout>
            <Seo title={page.title} meta={[
                { property:'og:url', content:location.href }
            ].concat(SocialMediaLinks(data.favicon?.childImageSharp.original.src))}/>
            <h1 className="block my-5 text-center text-red-700 text-md md:text-5xl">
                S<span className="text-stretch">e</span>n<span className="text-stretch">d</span> a M<span className="text-stretch">e</span>ss<span className="text-stretch">a</span>ge
            </h1>

            <div className="relative flex items-center justify-center overflow-hidden lg:mt-16" 
                style={{minHeight:'460px'}}>

                <div className="absolute left-0 grid w-full place-items-center place-content-center" 
                    style={{height:'460px'}}>

                    <StaticImage src="../../images/bg-contact-form.png" 
                        className="top-0 mx-auto" 
                        width={700}
                        style={{ minWidth:'700px' }} alt="Background Teared Paper"/>
                </div>

                <form onSubmit={e => {
                    e.preventDefault();
                    sendInquiry({ variables: {
                        id: uuidv4(),
                        name: name.value,
                        email: email.value,
                        message: message.value
                    } });
                    status = 'Sent'
                }} className="">

                    <div className="m-5">
                        
                        <div className="max-w-lg pt-6 space-y-8" style={{ width: '400px', maxWidth:'90vw' }}>
                            <div className="relative z-10 border-b-2 border-gray-400 focus-within:border-red-500">
                                <input ref={node => name = node} 
                                    className="block w-full pb-1 font-sans bg-transparent appearance-none focus:outline-none" type="text" placeholder=" " id="name"/>
                                <label className="absolute top-0 text-red-600 -z-1 origin-0" htmlFor="name">Name*</label>
                            </div>

                            <div className="relative z-10 border-b-2 border-gray-400 focus-within:border-red-500">
                                <input ref={node => email = node} 
                                    className="block w-full pb-1 font-sans bg-transparent appearance-none focus:outline-none" type="email" placeholder=" " id="email"/>
                                <label className="absolute top-0 text-red-600 -z-1 origin-0" htmlFor="email">Email*</label>
                            </div>

                            <div className="relative z-10 border-b-2 border-gray-400 focus-within:border-red-500">
                                <textarea ref={node => message = node} 
                                    className="block w-full font-sans bg-transparent appearance-none focus:outline-none" placeholder=" " id="message" rows="4"/>
                                <label className="absolute top-0 text-red-600 -z-1 origin-0" htmlFor="message">Message*</label>
                            </div>
                        </div>
                    </div>

                    <div className="relative flex my-5" >
                        {status && 
                            <span>{status}</span>
                        }
                        <button className="inline-block p-3 m-auto text-white bg-red-600" type="submit">Submit</button>
                    </div>

                </form>

            </div>
            
        </Layout>
    )
}

export default PageContactUs